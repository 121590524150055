/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@gbds/components/scss/gm-generic/gm-generic.scss';
@import 'custom-theme.scss';
@import 'content/sass/_library';
@import 'content/sass/_utility';

$gmcard-primary: mat-color($mat-blue, 500);
$gmcard-background: mat-color($mat-grey, 200);

html,
body {
  height: 100%;
}

body {
  background: $gmcard-background;
  margin: 0;
  font-family: $font-gotham;
  color: $black;
}

/* === Font Overrides === */

body [class^='mat-'],
body [class*=' mat-'] {
  font-family: $font-gotham;

  .material-icons {
    font-family: 'Material Icons';
  }
}

sup {
  vertical-align: super !important;
  line-height: 0 !important;
  top: 0em !important;
  font-size: 10px !important;
}

@media (max-width: $mobile-landscape) {
  body [class^='mat-'],
  body [class*=' mat-'] {
    font-family: $font-gotham-mobile;

    .material-icons {
      font-family: 'Material Icons';
    }
  }
}

.container {
  background-color: $white;
  flex-wrap: wrap;
  padding: 2em 1em 1.5em 1.5em;
}

.two-button-container {
  display: flex;
}

.two-button-container-item {
  margin-right: 0.4rem !important;
}

.xs-margin {
  margin-bottom: 0.5rem !important;
}

p {
  margin: 1em 0;
  color: grey;
  font-size: 16px;
  line-height: 20px;
}

.title {
  font-size: 2em;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 20px;
  color: $light-blue;
}

.book {
  font-weight: $font-weight-book !important;
}

.font-gotham {
  font-family: $font-gotham !important;
}

.font-gotham-bold {
  font-family: $font-gotham-bold !important;
  font-weight: $font-weight-bold;
}

p .book {
  font-size: 0.8rem;
}

hr {
  height: 0.25em;
  width: 100%;
  background-color: $dark-blue;
}

.info {
  text-align: center;
  font-weight: 400;
  padding-top: 2rem;
  max-width: 550px;
  margin: 0 auto;
}

.info-padding-top {
  padding-top: 2rem;
}

.column {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}

.column-no-justify {
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  &-left {
    align-items: left;
  }
}

.row-no-center {
  flex-direction: row;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
}

.row {
  flex-direction: row;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.header-strong {
  color: #369;
  font-weight: 700;
  font-size: 18pt;
}

.blue-text {
  color: #369;
}

/* === Buttons === */

.button-list {
  width: 50%;
  display: inline-block;
}

.cta-primary {
  color: $white;
  background-color: $green;
  padding: 0.65rem 2.85rem 0.5rem;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase !important;
  display: inline-block;
  white-space: nowrap;
  font-weight: 700;
  text-decoration: none;
  border: 0;
}

.green-button {
  cursor: pointer;
  min-width: 70%;
  margin: 1rem 0;
  padding: 0.65rem 0.5rem 0.5rem;
  background-color: $green;
  color: $white;
  border-radius: 0px;
  border: none;
  text-transform: uppercase !important;
  font-weight: $font-weight-bold;
  font-family: 'Gotham SSm A', 'Gotham SSm B', Helvetica, Arial, sans-serif !important;

  &:active,
  &:focus,
  &:hover {
    background-color: $green;
    border: none;
    color: $white;
  }

  &[disabled] {
    background-color: $light-grey;
    color: $dark-grey;
  }
}

.blue-button {
  cursor: pointer;
  min-width: 70%;
  margin: 1rem 0;
  padding: 0.65rem 0.5rem 0.5rem;
  background-color: $med-blue;
  color: $white;
  border-radius: 0px;
  border: none;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-family: 'Gotham SSm A', 'Gotham SSm B', Helvetica, Arial, sans-serif !important;

  &:active,
  &:focus,
  &:hover {
    background-color: $med-blue;
    border: none;
    color: $white;
  }

  &[disabled] {
    background-color: $dark-grey;
    color: $white;
  }
}

.popup-white-button {
  cursor: pointer;
  width: 60%;
  display: block;
  margin: 0 auto;
  padding: 1rem 0.5rem 1rem 0.5rem;
  background-color: $white;
  color: $med-blue;
  border-radius: 1.5rem;
  font-size: 24px;
  font-weight: $font-weight-bold;
  font-family: $font-gotham-bold !important;

  &:active,
  &:focus,
  &:hover {
    background-color: $white;
    border: none;
    color: $popup-bg-blue;
  }
}

.light-grey-button {
  cursor: pointer;
  min-width: 70%;
  margin: 1rem 0;
  padding: 0.65rem 0.5rem 0.5rem;
  background-color: $light-grey;
  color: $dark-grey;
  border: $dark-grey 1px solid;
  border-radius: 0px;
  text-transform: capitalize;

  &:active,
  &:focus,
  &:hover {
    background-color: $light-grey;
    border: $dark-grey 1px solid;
    color: $dark-grey;
  }
}

@media (max-width: 769px) {
  .button-list {
    width: 100%;
  }
}

.blue-dialog {
  .mat-mdc-dialog-container {
    background-color: $popup-bg-blue;
    color: $white;
    border-radius: 0;
  }
}

/* === Text Alignment === */

.center-text {
  text-align: center;
}

.text-left {
  text-align: left;
}

/* === Input Styles === */

.error {
  color: $red !important;
}

.error-list {
  text-align: left;
}

/* === Links === */
.pointer {
  cursor: pointer;
}

a.no-underline-link {
  cursor: pointer;
  color: #369;
  text-decoration: none;
  background-color: transparent;
}

a {
  cursor: pointer;
  color: #369;
  text-decoration: underline;
  background-color: transparent;

  &:active {
    outline: 0;
    border: 0;
  }

  &:hover {
    outline: 0;
  }

  &:focus {
    outline: dotted 3px #107cb1;
  }
}

/* === Material Design components for Angular === */

.mat-mdc-card {
  padding: 0 !important;

  &-content {
    padding: 5px 10px !important;
  }

  &-header {
    background-color: $dark-blue;
    color: $white;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

.business .mat-mdc-card {
  padding: 0 !important;

  &-content {
    padding: 5px 10px !important;
  }

  &-header {
    background-color: $black;
    color: $white;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

.mat-ink-bar {
  background: $white !important;
}

.mat-mdc-tab-link {
  height: 2rem !important;
  font-size: 0.5rem !important;
  text-transform: uppercase;
}


.mat-mdc-tab-link.mat-tab-label-active {
  opacity: 1;
}

.mat-expanded .mat-expansion-panel-header-title span {
  padding-left: 0.5em;
}

.mat-expansion-panel-header-title span {
  padding-left: 0.5em;
}

/* displays plus sign when panel is closed */
.mat-expansion-panel-header-title .plus,
.mat-expanded .mat-expansion-panel-header-title .minus {
  display: inline-block;
}

/* displays minus sign when panel is open */
.mat-expanded .mat-expansion-panel-header-title .plus,
.mat-expansion-panel-header-title .minus {
  display: none;
}

mat-slider.mat-slider-horizontal.mat-slider-brick {
    .mat-slider-thumb-label {
    transform: translateY(-50%);
    border-radius: 4px !important;
    box-shadow: 0px 0px 2px black;
    width: 50px;
    height: 25px;
    top: 50%;
    right: -25px;
    background-color: $gmcard-primary !important;

    &-text {
      transform: none;
      opacity: unset;
    }
  }
}

.show-on-mobile {
  display: none;
}

@media (max-width: $mobile-landscape) {
  ::ng-deep .full-size-dialog {
    height: 100vh !important;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .show-on-mobile {
    display: block !important;
  }
}

.cardmember-offers {
  .align-total, .bonus-combine {
    max-width: 420px;
  }

  .mobile-bonus-image {
    display: none;

    a {
      display:block;
      margin: 0 auto;
      max-width: 233px;
      margin-top: 20px;
    }

    img {
      display:block;
      margin: 0 auto;
    }
  }

  .bonus-offer-table {
    display: flex;
    justify-content: space-around;

    div {
      display: flex;
    }

    span {
      color:#0076b9;
      font-weight: 800;
    }
  }

  .bonus-offer-section-right {
    flex-basis: 50%;

    div {
      display: block; margin: 0 auto
    }

    .bonus-bold {
      font-size: 21px;
      font-weight: 500;
    }

    .bonus-expiration {
      font-size: 14px;
    }

    .bonus-combine {
      font-size: 18px;
      text-align: left;
    }

    .bonus-line {
      border-top: 2px solid black;
      width: 100%;
      // max-width: 385px;
    }
  }

  .bonus-indent {
    padding-left: 20px;
  }

  .bonus-cta-btn {
    width: 233px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center !important;
  }

  .desktop-bonus-image-parent {
    display: flex;
  }

  .desktop-bonus-image {
    flex-basis: 40%;
  }

  .banner-large-asterisk {
    font-size: 22px !important;
    padding-left: 0 !important;
    color: #FFF !important;
  }

  .body-large-asterisk {
    font-size: 20px !important;
    color: #000 !important;
  }

  .disclaimer-large-asterisk {
    font-size: 18px !important;
    color: #000 !important;
  }

  @media (max-width: 900px) {
    .mobile-bonus-image {
      display: block !important;
    }

    .desktop-bonus-image {
      display: none !important;
    }

    .bonus-offer-section-right {
      flex-basis: 100% !important;
    }

    .bonus-offer-table {
      display: block !important;

      div {
        display: block !important;
      }
    }
  }
}
