.nowrap {
  white-space: nowrap;
}

.nobreak {
  word-break: keep-all;
  white-space: nowrap;
  padding-left: 0 !important;
}

.text-bold {
  font-weight: bold;
}

.text-bolder {
  font-weight: bolder;
}

.text-black {
  color: $black !important;
}

.image-center {
  display: block;
  margin: 0 auto;
}

.no-top {
  margin-top: 0 !important;
}

.no-bottom {
  margin-bottom: 0 !important;
}
