/*=========fonts
================*/
$font-gotham-mobile: 'Gotham SSm A', 'Gotham SSm B', Helvetica, Arial,
  sans-serif;
$font-gotham: 'Gotham A', 'Gotham B', Helvetica, Arial, sans-serif;
$font-gotham-bold: 'Gotham Bold', 'Gotham A', 'Gotham B', Helvetica, Arial,
  sans-serif;
$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-base: 14px;
$font-desktop-base: 16px;
$font-h1: 60px;
$font-h2: 36px;
$font-h3: 30px;
$font-cta: $font-base;

/*====================== Colors
==============================*/
$dark-blue: #003066;
$faded-blue: #d0dae3;
$light-blue: #107cb1; //was#1392D1;
$blue: #336699;
$accent-blue: #0033a1;
$dark-grey: #333333;
$med-blue: #012e67;
$dark-blue: #073264;
$darker-grey: #555555;
$med-grey: #666;
$light-grey: #f8f8f8;
$lighter-grey: #e0e0e0;
$global-nav-grey: #ebeaea;
$normal-grey: #aaa;
$select-grey: #c2c0c6;
$dark-green: #29552b;
$green: #388553;
$light-green: #108a12;
$offer-green: #00864f;
$white: #fff;
$black: #000;
$slider-business-black: #333;
$efc-orange: #f7941d;
$business-orange: #f5a61a;
$popup-bg: #f3f3f3;
$popup-border: #ccc;
$red: #ee0700;
$light-grey-sub-text: #797676;
$form-grey: #6c6d70;
$form-placeholder-grey: #a6a6a6;
$popup-bg-blue: #083165;
$errorBackground: #ffbaba;
$file-select-grey: #a7a6a6;
$character-count: #a7a6a6;
$paginaton-arrow-grey: #bababa;
$button-disabled-grey: #e3e2e2;
$form-select-grey: #dbdbdb;
$accordion-border: #ccc;
$accordion-gradient: #ebeff3;
$mail-offer-green: #669966;
$offer-background-grey: #f7f1f3;

/*==================== Margins
==============================*/

/*=============== Screen Sizes
==============================*/
$mobile-portrait: 320px;
$mobile-average: 480px;
$mobile-landscape: 576px;
$desktop-small: 768px;
$desktop-average: 992px;
$tablet-landscape: 1024px;
$desktop-large: 1200px;
$desktop-xl: 1600px;
$desktop-xxl: 1920px;

/*==================== Forms
==============================*/
$submit-btn-opacity: 0.5;
